<template>
	<div id="print" ref="print">
		<img src="/static/image/printLogo.png" width="290px">
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==11">博罗中等专业学校呈批件办理表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==1">博罗中等专业学校活动申请表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==2">博罗中等专业学校请假条</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==3">博罗中等专业学校公物申购表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==5">博罗中等专业学校报修审批表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==8">博罗中等专业学校物品领用办理表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==9">博罗中等专业学校物品借用办理表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==12">博罗中等专业学校公务出差申请表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==13">博罗中等专业学校阶梯教室及录播室申请表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==14">博罗中等专业学校公章使用申请表</div>
		<div align="center" style="font-size:24px;font-weight: bold;padding: 20px 0;" v-if="type==15">博罗中等专业学校校车使用申请表</div>
		<div class="u-f-right" style="padding-bottom: 10px;" v-if="type!=5">
			<div class="u-f-item" style="margin-left: 15px;font-size: 16px;">经办人：
				<div style="padding-right: 20px;font-size: 16px;">
					{{info.petitioner_name}}
				</div>
			</div>
		</div>
		<div class="u-f-item u-f-jsb" style="padding-bottom: 10px;" v-if="type==5">
			<div style="font-size: 16px;padding-left: 10px;">维修编号：{{info.updatetime}}</div>
			<div class="u-f-item" style="margin-left: 15px;font-size: 16px;">经办人：
				<div style="padding-right: 20px;font-size: 16px;">
					{{info.petitioner_name}}
				</div>
			</div>
		</div>

		<div class="table" v-if="type==1">
			<div class="u-f-item box">
				<div class="left u-f-justify">活动标题</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.title}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">开始时间</div>
				<div class="u-f1 right u-f-justify">{{info.start_time}}</div>
				<div class="left u-f-justify">结束时间</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.end_time}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 100px;">活动说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify"  style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==2">
			<div class="u-f-item box">
				<div class="left u-f-justify">申请人</div>
				<div class="u-f1 right u-f-justify">{{info.petitioner_name}}</div>
				<div class="left u-f-justify">请假类型</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.type_text}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">开始时间</div>
				<div class="u-f1 right u-f-justify">{{info.start_time_text}}</div>
				<div class="left u-f-justify">结束时间</div>
				<div class="u-f1 right u-f-justify">{{info.end_time_text}}</div>
				<div class="left u-f-justify">请假时长</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.day}}天</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 100px;">请假说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left"  style="height: 150px;line-height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==3">
			<div class="u-f-item box">
				<div class="left u-f-justify">标题</div>
				<div class="u-f1 right u-f-justify">{{info.title}}</div>
				<div class="left u-f-justify">预算金额</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.expenditure}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 100px;">物品清单</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;"><span  v-for="(item,index) in info.inventory" :key="index">{{item.name}}*{{item.number}} <span v-if="info.inventory.length!=index+1">,</span></span></div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 100px;">用途</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left"  style="height: 150px;line-height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==4"></div>
		<div class="table" v-if="type==5">
			<div class="u-f-item box">
				<div class="left u-f-justify">设备名称</div>
				<div class="u-f1 right u-f-justify">{{info.property_warehouse_name?info.property_warehouse_name:'自定义'}}</div>
				<div class="left u-f-justify">拟用公司</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.company_info}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">预算经费</div>
				<div class="u-f1 right u-f-justify">{{info.expenditure}}</div>
				<div class="left u-f-justify">申请维修时间</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.receive_time_text}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;" v-if="info.property">
				<div class="left u-f-justify" style="height: 100px;">报修说明</div>
				<div class="u-f1 right1 u-f-item" style="height: 100px;">{{info.property}}</div>

			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 150px;">详细说明</div>
				<div class="u-f1 right1 u-f-item" style="height: 100px;border-right: 1px solid #000;">{{info.explain}}</div>
				<div style="border: none;height: 100px;width: 120px;">
					<div style="padding: 10px;font-size: 16px;border-bottom: 1px solid #000;">部门确认</div>
				</div>
			</div>
			<div class="u-f-item box"  style="height: 200px;">
				<div class="left"  style="height: 200px;line-height: 200px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">备注</div>
				<div class="u-f1 right" style="border: none;border-right: 1rpx solid #000;"></div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify"  style="height: 150px;">验收小组</br>确认</div>
				<div class="u-f1 right" style="border: none;border-right: 1rpx solid #000;height: 150px;"></div>
			</div>
		</div>
		<div class="table" v-if="type==6"></div>
		<div class="table" v-if="type==7"></div>
		<div class="table" v-if="type==8">
			<div class="u-f-item box">
				<div class="left u-f-justify">物品编号</div>
				<div class="u-f1 right u-f-justify">{{info.sn}}</div>
				<div class="left u-f-justify">物品名称</div>
				<div class="u-f1 right u-f-justify">{{info.name}}</div>
				<div class="left u-f-justify">申请数量</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.number}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify" style="height: 100px;">详细说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.purpose}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left"  style="height: 150px;line-height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;<strong></strong>" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==9">
			<div class="u-f-item box">
				<div class="left">物品编号</div>
				<div class="u-f1 right">{{info.sn}}</div>
				<div class="left">物品名称</div>
				<div class="u-f1 right">{{info.name}}</div>
				<div class="left">申请数量</div>
				<div class="u-f1 right" style="border: none;">{{info.number}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left">预期归还时间</div>
				<div class="u-f1 right" style="border: none;">{{info.plantime}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;line-height: 100px;">
				<div class="left">详细说明</div>
				<div class="u-f1 right1" style="border: none;">{{info.purpose}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left"  style="height: 150px;line-height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==10"></div>
		<div class="table" v-if="type==11">
			<div class="u-f-item box">
				<div class="left u-f-justify">紧急程度</div>
				<div class="u-f1 right u-f-justify" style="border: none;">
					<span v-if="info.level==1">普通</span>
					<span v-if="info.level==2">紧急</span>
					<span v-if="info.level==3">特急</span>
				</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">来文单位</div>
				<div class="u-f1 right u-f-justify">{{info.origin_units}}</div>
				<div class="left u-f-justify">来文日期</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.origin_time}}</div>
				<!-- <div class="left">办文编号</div>
				<div class="u-f1 right" style="border: none;"></div> -->
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">标题</div>
				<div class="u-f1 right1 u-f-item" style="border: none;">{{info.title}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">完成时间</div>
				<div class="u-f1 right1 u-f-item" style="border: none;">{{info.updatetime|getLocalTime}}</div>
			</div>
			<div class="u-f-item box" style="height: 150px;">
				<div class="left u-f-justify" style="height: 150px;">来文摘要</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 150px;">{{info.describe}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify"  style="height: 100px;">拟办意见</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.opinion}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify"  style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
			<div class="u-f-item box" style="height: 50px;line-height: 50px;">
				<div class="left u-f-justify">完成归档</br>情况</div>
				<div class="u-f1 right1" style="border: none;">
					<div class="u-f-item" v-for="(item,index) in info.exec" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">{{item.audit_opinions}}</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="!item.audit_opinions">完成归档</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table" v-if="type==12">
			<div class="u-f-item box">
				<div class="left u-f-justify">申请人</div>
				<div class="u-f1 right u-f-justify">{{info.petitioner_name}}</div>
				<div class="left u-f-justify">岗位</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.post}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">开始时间</div>
				<div class="u-f1 right u-f-justify">{{info.start_time_text}}</div>
				<div class="left u-f-justify">结束时间</div>
				<div class="u-f1 right u-f-justify">{{info.end_time_text}}</div>
				<div class="left u-f-justify">出差时长</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.day}}天</div>
			</div>
<!--			<div class="u-f-item box">-->
<!--				<div class="left u-f-justify">交通工具</div>-->
<!--				<div class="u-f1 right u-f-justify">{{ info.vehicle}}</div>-->
<!--				<div class="left u-f-justify">金额</div>-->
<!--				<div class="u-f1 right u-f-justify" style="border: none;">{{info.money||0}}</div>-->
<!--			</div>-->
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify"style="height: 100px;">出差说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify" style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="table" v-if="type==13">
			<div class="u-f-item box">
				<div class="left u-f-justify">申请人</div>
				<div class="u-f1 right u-f-justify">{{info.petitioner_name}}</div>
				<div class="left u-f-justify">场地</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.local}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">使用日期</div>
				<div class="u-f1 right u-f-justify">{{info.addtime}}</div>
				<div class="left u-f-justify">部门</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{ info.department}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">节次</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.jieci.toString()}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify"style="height: 100px;">使用说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify" style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="table" v-if="type==14">
			<div class="u-f-item box">
				<div class="left u-f-justify">申请人</div>
				<div class="u-f1 right u-f-justify">{{info.petitioner_name}}</div>
				<div class="left u-f-justify">使用类型</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.type}}</div>
			</div>
			<div class="u-f-item box">
				<div class="left u-f-justify">开始时间</div>
				<div class="u-f1 right u-f-justify">{{info.addtime}}</div>
				<div class="left u-f-justify">结束时间</div>
				<div class="u-f1 right u-f-justify">{{info.endtime}}</div>
				<div class="left u-f-justify">份数</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.amount}}份</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify"style="height: 100px;">使用说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify" style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="table" v-if="type==15">
			<div class="u-f-item box">
				<div class="left u-f-justify">申请人</div>
				<div class="u-f1 right u-f-justify" style="border: none;">{{info.petitioner_name}}</div>
			</div>
			<div class="u-f-item box" style="height: 100px;">
				<div class="left u-f-justify"style="height: 100px;">使用说明</div>
				<div class="u-f1 right1 u-f-item" style="border: none;height: 100px;">{{info.explain}}</div>
			</div>
			<div class="u-f-item box"  style="height: 150px;">
				<div class="left u-f-justify" style="height: 150px;">领导批示</div>
				<div class="u-f1 right1" style="border: none;height: 150px;padding-top: 10px;">
					<div class="u-f-item" v-for="(item,index) in info.audit" :key="index"  v-if="item.handle==2">
						<div style="font-size: 16px;">{{item.audit_username}}</div>
						<div style="margin-left: 10px;font-size: 16px;">{{item.audit_time|getLocalTime}}</div>
						<div style="margin-left: 10px;font-size: 16px;">已审批</div>
						<div style="margin-left: 10px;font-size: 16px;" v-if="item.audit_opinions">批示：{{item.audit_opinions}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="u-f-item u-f-jsb" style="padding: 15px;">
			<div style="font-size: 16px;">惠州市博罗中等专业学校办公室</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info:'',
				id:'',
				type:''
			};
		},
		filters:{
			getLocalTime(nS) {
				return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
			}
		},
		mounted() {
			if(this.$route.query.token){
				window.localStorage.setItem("token",this.$route.query.token)
			}
			if(this.$route.query.campusid){
				window.localStorage.setItem("campusid",this.$route.query.campusid)
			}
			this.id = this.$route.query.id;
			this.type = this.$route.query.type;
			if(this.type==1){
				this.activitDetails(this.id)
			}else if(this.type==2){
				this.leaveDetails(this.id)
			}else if(this.type==3){
				this.getmaterialsGet(this.id)
			}else if(this.type==5){
				this.repairDetails(this.id)
			}else if(this.type==8){
				this.propertyBorrowDetails(this.id)
			}else if(this.type==9){
				this.propertyBorrowDetails(this.id)
			}else if(this.type==11){
				this.getDocument(this.id)
			}else if(this.type==12){
				this.getMyofficialGet(this.id)
			}else if(this.type==13){
				this.ladderDetails(this.id)
			}else if(this.type==14){
				this.chapterDetails(this.id)
			}else if(this.type==15){
				this.schoolbusDetails(this.id)
			}
		},
		methods: {
			// 校车使用
			schoolbusDetails(id) {
				this.$api.rule.getschoolbusDetail({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 公章使用
			chapterDetails(id) {
				this.$api.rule.getchapterDetail({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 功能室
			ladderDetails(id) {
				this.$api.rule.getladderDetail({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 申购
			getmaterialsGet(id) {
				this.$api.rule.getmaterialsGet({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 校园报修repairDetails
			repairDetails(id) {
				this.$api.rule.repairDetails({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 资产管理
			propertyBorrowDetails(id) {
				this.$api.rule.propertyBorrowDetails({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 活动详情
			activitDetails(id) {
				this.$api.rule.activitDetails({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 请假详情
			leaveDetails(id) {
				this.$api.rule.leaveDetails({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 公务出差
			getMyofficialGet(id) {
				this.$api.rule.getMyofficialGet({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			},
			// 公文详情
			getDocument(id){
				this.$api.rule.getDocument({ id: id }).then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data;
						setTimeout(()=>{
							this.$print(this.$refs.print)
						},1000)
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	#print{
		width: 860px;
		margin: 0 auto;
		padding: 30px;
		font-size: 16px;
		.table{
			border: #000000 1px solid;
			font-size: 16px;
			.box{
				border-bottom: #000000 1px solid;
				height: 50px;
				font-size: 16px;
				.left{
					width: 100px;
					height: 50px;
					text-align: center;
					border-right: #000000 1px solid;
					font-size: 16px;
				}
				.right{
					border-right: #000000 1px solid;
					text-align: center;
					height: 50px;
					font-size: 16px;
				}
				.right1{
					padding:0 15px;
					font-size: 16px;
				}
			}
			&>div:last-child{
				border: none;
				font-size: 16px;
			}
		}
	}

</style>
